import { required, minLength, sameAs, helpers } from 'vuelidate/lib/validators';

const isHasUppercaseLetter = helpers.regex('isHasUppercaseLetter', /[A-Z]+/);
const isHasNumber = helpers.regex('isHasNumber', /\d+/);

export default {
  validations: {
    confirmPassword: {
      required,
      sameAsPassword: sameAs('password'),
      minLength: minLength(8),
      isHasUppercaseLetter,
      isHasNumber,
    },
  },
  computed: {
    isConfirmPasswordError() {
      return (
        (this.$v.confirmPassword.$dirty && this.$v.confirmPassword.$invalid) ||
        !!this.getErrors.password_confirmation ||
        !!this.getErrors.new_password_confirmation
      );
    },

    confirmPasswordErrorText() {
      if (!this.$v.confirmPassword.required) {
        return 'The field is required';
      }
      if (!this.$v.confirmPassword.sameAsPassword) {
        return `The passwords don't match`;
      }
      if (!this.$v.confirmPassword.minLength) {
        return 'Password must be at least 8 characters';
      }
      if (!this.$v.confirmPassword.isHasUppercaseLetter) {
        return 'Password must be at least with 1 upper case letter';
      }
      if (!this.$v.confirmPassword.isHasNumber) {
        return 'Password must be at least with 1 number';
      }
      if (!!this.getErrors.password_confirmation) {
        return this.getErrors.password_confirmation[0];
      }
      if (!!this.getErrors.new_password_confirmation) {
        return this.getErrors.new_password_confirmation[0];
      }
    },
    confirmPasswordStrength() {
      return {
        minLength:
          this.$v.confirmPassword.required && this.$v.confirmPassword.minLength,
        isHasUppercaseLetter:
          this.$v.confirmPassword.required &&
          this.$v.confirmPassword.isHasUppercaseLetter,
        isHasNumber:
          this.$v.confirmPassword.required &&
          this.$v.confirmPassword.isHasNumber,
      };
    },
  },
};
